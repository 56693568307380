<template>
  <v-container>
    <template v-if="res">
      <div class="d-flex flex-row align-center justify-space-between">
        <page-title :title="storeName" />
      </div>

      <div class="mb-5">
        <p>
          <span class="primary--text">NT${{ total }} / {{ `apply-pay.status.${status}`|t }} /</span> {{ `data.payment_type.${storePaymentType}`|t }}
        </p>
        <p>
          請款時間： {{ applyPayDate }}
        </p>
        <p>
          訂單日期： {{ orderDate }}
        </p>
      </div>


      <p class="pb-2 border-bottom-1 mb-2 primary--text">共{{ count }}筆訂單</p>
      <v-divider />

      <div class="mb-10">
        <apply-pay-card
            v-for="(item, index) in cardData"
            :key="index"
            v-model="cardData[index]"
            :configs="cardConfigs(index)"
        ></apply-pay-card>
      </div>

      <number-bar
          title="請款金額"
          unit="元"
          :value="total"
          readonly
          type="secondary"
          class="mb-5"
          :fixed="3"
      ></number-bar>

      <v-btn
        depressed
        color="primary"
        small
        :disabled="successDisabled"
        @click="showModal = !showModal"
        >款項已到帳</v-btn
      >
    </template>

    <confirm-apply-pay-modal
      v-if="showModal"
      title="是否確認商家已付款？"
      @apply="apply"
      @close="close"
    ></confirm-apply-pay-modal>
  </v-container>
</template>

<script>
import applyPayDetail from "mixins/applyPayDetail";

export default {
  mixins: [applyPayDetail],
  components: {
    'confirm-apply-pay-modal': () => import('modules/provider/components/modal/confirmApplyPayModal.vue'),
  },
  data: () => ({
    showModal: false,
  }),
  computed: {
    providerId() {
      return this.$store.getters['member/providerId']
    },
    successDisabled() {
      return this.status !== 'pending'
    }
  },
  methods: {
    back() {
      this.$router.push({
        name: 'apply-pay-overview'
      })
    },
    close() {
      this.showModal = false
    },
    async apply() {
      this.$store.dispatch("loading/active");
      try {
        const params = {
          status: 'success'
        };
        await this.$api.collection.applyPayApi.update({
          provider: this.providerId,
          applyPayId: this.applyPayId,
          params
        });
        await this.getData();
        this.$snotify.success("成功");

      } catch (err) {
        console.error(err);
        this.$snotify.error("發生錯誤");
      } finally {
        this.$store.dispatch("loading/close");
        this.showModal = false
      }
    }
  },
}
</script>

<style lang="sass" scoped>
.v-btn
  width: 100%
  height: 55px !important
  font-size: 16px
</style>
